import { getCodeFromLocation } from "../utils/getCodeFromLocation";
import { atomWithDebounce } from "../jotai/atomWithDebounce";
import { atomCommand } from "../jotai/atomCommand";
import { PLACEHOLDER_CODE } from "../constants";

const LOCAL_STORAGE_KEY = "quickulator-code";

const codeFromLocalStorage = localStorage.getItem(LOCAL_STORAGE_KEY);

const { currentValueAtom, debouncedValueAtom } = atomWithDebounce<string>(
  getCodeFromLocation() ?? codeFromLocalStorage ?? PLACEHOLDER_CODE
);

export const codeAtom = currentValueAtom;

export const debouncedCodeAtom = debouncedValueAtom;

export const setCodeCommand = atomCommand((_get, set, update: string) => {
  localStorage.setItem(LOCAL_STORAGE_KEY, update);
  set(debouncedCodeAtom, update);
});

export const clearCodeCommand = atomCommand((_get, set) => {
  set(debouncedCodeAtom, "");
});
