import { css } from "@emotion/react";
import { transition } from "./transition";

export const navigationCss = css`
  a {
    color: var(--active-fg);
    text-decoration: none;
    border-bottom: 1px solid transparent;

    transition: ${transition("border-color")};

    &:hover,
    &:focus,
    &:active {
      border-bottom: 1px solid var(--active-fg);
    }
  }
`;
