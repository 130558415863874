import { css } from "@emotion/react";
import { useAtomValue, useSetAtom } from "jotai";
import { ChangeEvent, FC, useCallback, useEffect, useRef } from "react";
import { useGetAtom } from "../jotai/useGetAtom";
import { codeAtom, cursorAtom, setCodeCommand } from "../state";
import { getCharacterOffset } from "../utils/getCharacterOffset";
import { PLACEHOLDER_CODE } from "../constants";

const textareaCss = css``;

export const Editor: FC = () => {
  const code = useAtomValue(codeAtom);
  const setCode = useSetAtom(setCodeCommand);

  const onChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      setCode(event.target.value);
    },
    [setCode]
  );

  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const cursor = useAtomValue(cursorAtom);

  const get = useGetAtom();

  useEffect(() => {
    if (cursor && "line" in cursor) {
      const code = get(codeAtom);

      const offset = getCharacterOffset(code, cursor.line);

      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
          inputRef.current.setSelectionRange(offset, offset);
        }
      }, 100);
    } else if (cursor && "end" in cursor) {
      const code = get(codeAtom);

      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
          inputRef.current.setSelectionRange(code.length, code.length);
        }
      }, 100);
    }
  }, [get, cursor]);

  return (
    <textarea
      ref={inputRef}
      css={textareaCss}
      placeholder={PLACEHOLDER_CODE}
      spellCheck={false}
      value={code}
      onChange={onChange}
    />
  );
};
