import { Getter, Setter, WritableAtom, atom, useSetAtom } from "jotai";

export type AtomCommand<Args extends unknown[], Result> = WritableAtom<
  void,
  Args,
  Result
>;

export const atomCommand = <Args extends unknown[], Result>(
  command: (get: Getter, set: Setter, ...args: Args) => Result
): AtomCommand<Args, Result> => atom(null, command);

export const useAtomCommand = <Args extends unknown[], Result>(
  commandAtom: AtomCommand<Args, Result>
) => useSetAtom(commandAtom);
