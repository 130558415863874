import { css } from "@emotion/react";

export const BODY_FONT_FAMILY =
  "source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace";

export const typographyCss = css`
  small {
    font-size: 66%;
  }

  code {
    color: var(--active-fg);
  }

  kbd {
    font-size: 80%;
    color: var(--active-fg);
  }

  [data-active] {
    color: var(--active-fg);
  }

  [data-muted] {
    color: var(--fg-muted);
  }

  [data-text-align="start"] {
    text-align: start;
  }

  [data-text-align="center"] {
    text-align: center;
  }

  [data-text-align="end"] {
    text-align: end;
  }

  [data-text-wrap="nowrap"] {
    white-space: nowrap;
  }
`;
