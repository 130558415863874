export const getCodeFromLocation = () => {
  const { search } = window.location;

  if (search) {
    return decodeURIComponent(search)
      .replace("?chrome=false", "")
      .replace("?code=", "")
      .replace("&code=", "")
      .replace("&chrome=false", "")
      .replace("?chromeless", "")
      .replace("&chromeless", "")
      .replaceAll("\\n", "\n");
  }
};
