import { atom } from "jotai";
import { toString } from "lodash";
import { EvalResult } from "../types";
import { Script } from "../vm";
import { debouncedCodeAtom } from "./codeState";
import { resultToString } from "../utils/resultToString";

export const outputAtom = atom<EvalResult[]>((get) => {
  const code = get(debouncedCodeAtom);
  const codeLines = code.split("\n");

  const context = Script.createContext({});
  const script = new Script(context, "quickulator");

  const results = codeLines.map((codeLine, index): EvalResult => {
    try {
      const result = script.runInContext(codeLine);
      return {
        line: index,
        type: "value",
        value: resultToString(result ?? ""),
      };
    } catch (error: unknown) {
      return { line: index, type: "error", message: toString(error) };
    }
  });

  return results;
});
