export const getCharacterOffset = (text: string, line: number): number => {
  const lines = text.split("\n");
  let offset = 0;

  for (let i = 0; i < line + 1 && i < lines.length; i++) {
    offset += lines[i].length + 1;
  }

  return offset === text.length ? text.length : offset - 1;
};
