export const getConfigFromLocation = () => {
  const { search } = window.location;

  if (search) {
    const params = new URLSearchParams(search);
    const hasChrome =
      params.get("chrome") !== "false" && !params.has("chromeless");

    console.log(params.get("chromeless"));

    return {
      hasChrome,
    };
  }

  return {
    hasChrome: true,
  };
};
