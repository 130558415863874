import { css } from "@emotion/react";
import { ACTIVE_COLOR, BASE_COLOR, DANGER_COLOR } from "./colors";
import { BODY_FONT_FAMILY, typographyCss } from "./typography";
import { scheme } from "./scheme";
import { formsCss } from "./forms";
import { navigationCss } from "./navigation";
import { layoutCss } from "./layout";

export const globalCss = css`
  html,
  body {
    min-width: 100%;
    max-width: 100%;
    min-height: 100vh;
    font-family: ${BODY_FONT_FAMILY};
    font-weight: 400;
    margin: 0;
    padding: 0;
  }

  :root {
    --bg: ${BASE_COLOR[900]};
    --fg: ${BASE_COLOR[50]};
    --fg-muted: ${BASE_COLOR[400]};
    --fg-danger: ${DANGER_COLOR[400]};
    --border-color: ${BASE_COLOR[700]};
    --button-fg: ${BASE_COLOR[100]};
    --button-border: ${BASE_COLOR[100]};
    --button-active-bg: ${BASE_COLOR[950]};
    --active-fg: ${ACTIVE_COLOR[300]};
    --active-bg: ${ACTIVE_COLOR[950]};
    --disabled-fg: ${BASE_COLOR[500]};
    --backdrop: rgba(0, 0, 0, 0.75);

    --tooltip-bg: ${BASE_COLOR[800]};
    --input-size: 36px;
    --border-radius: 5px;

    --skeleton-base-color: ${BASE_COLOR[800]};
    --skeleton-highlight-color: ${BASE_COLOR[900]};
  }

  body {
    background-color: var(--bg);
    color: var(--fg);
  }

  @media ${scheme.light} {
    :root {
      --bg: ${BASE_COLOR[50]};
      --fg: ${BASE_COLOR[800]};
      --fg-muted: ${BASE_COLOR[500]};
      --fg-danger: ${DANGER_COLOR[800]};
      --border-color: ${BASE_COLOR[400]};
      --button-fg: ${BASE_COLOR[800]};
      --button-border: ${BASE_COLOR[800]};
      --button-active-bg: ${ACTIVE_COLOR[100]};
      --active-fg: ${ACTIVE_COLOR[600]};
      --active-bg: ${ACTIVE_COLOR[200]};
      --disabled-fg: ${BASE_COLOR[600]};
      --backdrop: rgba(0, 0, 0, 0.5);
      --tooltip-bg: ${BASE_COLOR[50]};

      --skeleton-base-color: ${BASE_COLOR[200]};
      --skeleton-highlight-color: ${BASE_COLOR[100]};
    }
  }

  ${typographyCss}
  ${navigationCss}
  ${formsCss}
  ${layoutCss}
`;
