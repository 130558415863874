import { css } from "@emotion/react";
import { useAtomValue } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { FC, MouseEvent, useCallback } from "react";
import { outputAtom } from "../state";
import { cursorAtom } from "../state/cursorState";
import { EvalResult } from "../types";

const containerCss = css`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: 50%;
`;

const outputLineCss = css`
  text-align: end;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  line-height: 150%;
  flex-shrink: 0;
`;

const errorCss = css`
  ${outputLineCss}
  color: var(--fg-danger);
`;

const valueCss = css`
  ${outputLineCss}
`;

const OutputLine: FC<EvalResult> = ({ line, ...props }) => {
  const onClick = useAtomCallback(
    useCallback(
      (_get, set, event: MouseEvent) => {
        event.stopPropagation();

        set(cursorAtom, { line });
      },
      [line]
    )
  );

  if (props.type === "error") {
    return (
      <div css={errorCss} onClick={onClick}>
        {props.message}
      </div>
    );
  }

  if (props.type === "value") {
    return (
      <div css={valueCss} onClick={onClick}>
        &nbsp;{props.value}
      </div>
    );
  }

  return <div css={errorCss}>Unhandled result on line {line}</div>;
};

export const Output: FC = () => {
  const output = useAtomValue(outputAtom);

  const onClick = useAtomCallback(
    useCallback((_get, set, event: MouseEvent) => {
      event.stopPropagation();

      set(cursorAtom, { end: true });
    }, [])
  );

  return (
    <div css={containerCss} data-textarea data-readonly onClick={onClick}>
      {output.map((result) => (
        <OutputLine key={result.line} {...result} />
      ))}
    </div>
  );
};
