import { css } from "@emotion/react";
import { mdiCalculatorVariant } from "@mdi/js";
import Icon from "@mdi/react";
import { FC } from "react";

const containerCss = css`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Hotkeys: FC = () => (
  <div css={containerCss} data-muted>
    <div data-flex data-align-items="center" data-gap="small">
      <div data-active data-flex data-align-items="center">
        <Icon path={mdiCalculatorVariant} size={0.66} color="currentColor" />
      </div>

      <small>Quickulator</small>
    </div>

    <div data-flex data-align-items="center" data-gap="small">
      <kbd>CTRL + L</kbd>
      <small> to clear</small>
    </div>
  </div>
);
