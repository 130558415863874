import { css } from "@emotion/react";
import { BODY_FONT_FAMILY } from "./typography";

export const formsCss = css`
  textarea,
  [data-textarea] {
    font-family: ${BODY_FONT_FAMILY};

    cursor: text;
    color: var(--fg);
    background-color: transparent;

    width: 100%;
    height: 100%;
    border: none;
    box-sizing: border-box;
    margin: 0;
    resize: none;
    overflow: visible;

    font-size: 1rem;
    line-height: 150%;

    &:focus {
      outline: none;
    }

    &[readonly],
    &[data-readonly] {
      color: var(--fg-muted);
    }
  }
`;
