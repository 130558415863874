import { css } from "@emotion/react";

export const layoutCss = css`
  [data-flex] {
    display: flex;
  }

  [data-gap="small"] {
    gap: 5px;
  }

  [data-align-items="center"] {
    align-items: center;
  }

  [data-align-self="start"] {
    align-self: start;
  }

  [data-align-self="center"] {
    align-self: center;
  }

  [data-align-self="end"] {
    align-self: end;
  }
`;
