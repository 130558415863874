import { css } from "@emotion/react";
import { FC } from "react";

const layoutCss = css`
  display: flex;
  gap: 10px;
`;

const getChromelessQueryString = () => {
  if (window.location.href.includes("?")) {
    return `${window.location.href}&chromeless`;
  }

  return `${window.location.href}?chromeless`;
};

export const Instructions: FC = () => (
  <div css={layoutCss}>
    <small data-muted data-text-align="start">
      A handy JavaScript-based calculator by{" "}
      <a href="https://cubicle6.com" target="_blank" rel="noreferrer">
        Caleb Peterson
      </a>
    </small>
    <small data-muted data-text-align="end">
      Pre-populate the input by providing a <code>code</code> URL query
      parameter. Hide chrome by appending{" "}
      <a href={getChromelessQueryString()}>
        <code>&chromeless</code>
      </a>{" "}
      to the URL.
    </small>
  </div>
);
