import { css } from "@emotion/react";
import { Editor } from "./components/Editor";
import { Instructions } from "./components/Instructions";
import { Output } from "./components/Output";
import { transition } from "./styles/transition";
import { getConfigFromLocation } from "./utils/getConfigFromLocation";
import { Hotkeys } from "./components/Hotkeys";
import { useHotKeys } from "./hooks/useHotKeys";

const containerCss = (hasChrome: boolean) => css`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
`;

const innerCss = css`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  max-width: 800px;
`;

const layoutCss = css`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  overflow: auto;
  border-radius: 10px;
  border: 1px solid var(--border-color);
  padding: 10px;

  transition: ${transition("border-color")};

  &:focus-within {
    border-color: var(--active-fg);
  }
`;

export default function App() {
  useHotKeys();

  const { hasChrome } = getConfigFromLocation();

  if (!hasChrome) {
    return (
      <div css={containerCss(hasChrome)}>
        <Editor />
        <Output />
        <Hotkeys />
      </div>
    );
  }

  return (
    <div css={containerCss(hasChrome)}>
      <div css={innerCss}>
        <div css={layoutCss}>
          <Editor />
          <Output />
          <Hotkeys />
        </div>

        <Instructions />
      </div>
    </div>
  );
}
